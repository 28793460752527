import { PERMISSIONS, ROLES, ROUTES } from 'shared/constants';

import User from 'models/User';

 type Item = {
   id: string;
   name_pt: string;
   name_en?: string;
   path: string;
   folder: string;
   icon?: React.ComponentType;
   allowed: string[]
 };

const items = (user: User): Item[] => ([
  {
    id: 'HOME',
    name_pt: 'Início',
    path: ROUTES.ROOT,
    folder: 'Home',
    allowed: PERMISSIONS.HOME.LIST,
  },
  {
    id: 'AUDIT',
    name_pt: 'Auditoria',
    path: ROUTES.AUDITS,
    folder: 'Audit',
    allowed: PERMISSIONS.AUDIT.LIST,
  },
  {
    id: 'SCHOOL-IMAGE',
    name_pt: 'Escola',
    path: ROUTES.SCHOOLS_EDIT_IMAGE,
    folder: 'schools',
    allowed: PERMISSIONS.SCHOOLS.EDIT_IMAGE,
  },
  {
    id: 'HIGHLIGHTS',
    name_pt: 'Avisos',
    path: ROUTES.HIGHLIGHTS,
    folder: 'highlights',
    allowed: PERMISSIONS.HIGHLIGHTS.LIST,
  },
  {
    id: 'SCHOOLS',
    name_pt: 'Escolas',
    path: ROUTES.SCHOOLS,
    folder: 'schools',
    allowed: PERMISSIONS.SCHOOLS.LIST,
  },
  {
    id: 'USERS',
    name_pt: user.role.id.toString() === ROLES.COORDINATOR ? 'Mediadores(as)' : 'Usuários(as)',
    path: ROUTES.USERS,
    folder: 'users',
    allowed: PERMISSIONS.USERS.LIST,
  },
  {
    id: 'SEGMENTS',
    name_pt: 'Segmentos',
    path: ROUTES.SEGMENTS,
    folder: 'subjects',
    allowed: PERMISSIONS.SEGMENTS.LIST,
  },
  {
    id: 'SUBJECTS',
    name_pt: 'Disciplinas',
    path: ROUTES.SUBJECTS,
    folder: 'subjects',
    allowed: PERMISSIONS.SUBJECTS.LIST,
  },
  {
    id: 'STUDENTS',
    name_pt: 'Estudantes',
    path: ROUTES.STUDENTS,
    folder: 'students',
    allowed: PERMISSIONS.STUDENTS.LIST,
  },
  {
    id: 'TEAMS',
    name_pt: 'Turmas',
    path: ROUTES.TEAMS,
    folder: 'teams',
    allowed: PERMISSIONS.TEAMS.LIST,
  },
  {
    id: 'SPECIAL_TEAMS',
    name_pt: 'Salas Multifuncionais',
    path: ROUTES.SPECIAL_TEAMS,
    folder: 'teams',
    allowed: PERMISSIONS.TEAMS.LIST,
  },
]);

export default items;
