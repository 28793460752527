import React from 'react';
import intl from 'react-intl-universal';

import { Typography, Box, SxProps, Theme } from '@mui/material';
import ConditionalContainer from 'components/ConditionalContainer';

const noResultsBoxStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: 200,
  width: '100%',
};

export enum Type {
  audit = 'audit',
  report = 'report',
  user = 'user',
  school = 'school',
  subject = 'subject',
  team = 'team',
  student = 'student',
  goal = 'goal',
  reportImage = 'reportImage',
  highlight = 'highlight',
  segment = 'segment',
};

interface Props {
  type: Type;
  filtered: boolean;
  show: boolean;
}

/**
 * Component for displaying no results text
 * @param {Props} props
 * @return {JSX.Element}
 */
export function NoResultsBox(props: Props): JSX.Element {
  return (
    <ConditionalContainer checkIf={props.show} noComponent>
      <Box id={`NoResultsBox${props.type}`} sx={noResultsBoxStyle}>
        <Typography variant="h1" color="primary" align="center">
          {getText(props.type, props.filtered, 'title')}
        </Typography>
        <Typography variant="caption">
          {getText(props.type, props.filtered, 'tip')}
        </Typography>
      </Box>
    </ConditionalContainer>
  );
};

const getText = (type: Type, isFiltered: boolean, textType: 'title' | 'tip') => {
  const filtered = isFiltered ? 'filtered' : 'unfiltered';
  const intlKey = `components.noResults.${type}.${filtered}.${textType}`;
  const message = intl.get(intlKey);
  return message;
};
