import React, { useContext, useEffect, useMemo, useState } from 'react';

import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TableContainer, Paper, TableBody, TableRow, TableCell, TablePagination, Table, TableHead, SxProps, Theme, IconButton, Tooltip, Grid, TextField, Autocomplete } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import intl from 'react-intl-universal';

import Audit from 'models/Audit';

import { ENDPOINTS, PAGINATION, ROUTES, UTILS } from 'shared/constants';
import { initIntl } from 'shared/locales';
import api from 'shared/api';

import User from 'models/User';
import Student from 'models/Student';

import AppContext from 'contexts/app.context';

import { NoResultsBox, Type } from 'components/NoResultsBox';

initIntl();

const getColumns = () => {
  const columns = [
    intl.get('pages.audits.user'),
    intl.get('pages.audits.student'),
    intl.get('pages.audits.team'),
    intl.get('pages.audits.subject'),
    intl.get('pages.audits.school'),
    intl.get('pages.audits.action'),
  ];

  return columns;
};

const actionsColStyle: SxProps<Theme> = {
  position: 'sticky',
  right: 0,
  backgroundColor: '#FFFFFF',
};

/**
 * Audits component
 * @return {JSX.Element}
 */
function Audits(): JSX.Element {
  const navigate = useNavigate();
  const { isLoading } = useContext(AppContext);
  const [audits, setAudits] = useState<Audit[]>([]);
  const [usersOptions, setUsersOptions] = useState<{label: string, value: string}[]>([]);
  const [selectedUser, setSelectedUser] = useState<{label: string, value: string} | null>(null);
  const [studentsOptions, setStudentsOptions] = useState<{label: string, value: string}[]>([]);
  const [selectedStudent, setSelectedStudent] = useState<{label: string, value: string} | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    from: 0,
    to: 0,
    total: 0,
  });

  useEffect(() => {
    getUsers();
    getStudents();
    getAudits();
  }, [page, rowsPerPage, selectedUser, selectedStudent]);

  const getAudits = async () => {
    const userId = selectedUser?.value ?? UTILS.BLANK;
    const studentId = selectedStudent?.value ?? UTILS.BLANK;
    const response = await api.get(
      `${ENDPOINTS.AUDITS.GET.ALL}?page=${page+1}&per_page=${rowsPerPage}&user_id=${userId}&student_id=${studentId}`,
    );
    setAudits(response.data.data);
    setPagination({
      from: response.data.from,
      to: response.data.to,
      total: response.data.total,
    });
  };

  const getUsers = async () => {
    const response = await api.get(ENDPOINTS.USERS.GET.LIST);
    setUsersOptions(response.data.map((user: User) => ({
      label: user.name,
      value: user.id.toString(),
    })));
  };

  const getStudents = async () => {
    const response = await api.get(ENDPOINTS.STUDENTS.GET.LIST);
    setStudentsOptions(response.data.map((student: Student) => ({
      label: student.name,
      value: student.id.toString(),
    })));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatName = (fullName: string) => {
    const names = fullName.split(UTILS.SPACE);

    let name =names[0];
    if (names[1]) {
      name = `${name}${UTILS.SPACE}${names[1].substring(0, 1)}${UTILS.DOT}`;
    }
    return name;
  };

  return (
    <>
      <Grid container mb={2} columnSpacing={1}>
        <Grid item xs={4}>
          <Autocomplete
            noOptionsText="Nenhum resultado encontrado"
            value={selectedUser}
            options={usersOptions}
            getOptionLabel={(option) => option.label}
            inputValue={selectedUser?.label}
            onChange={(_, value) =>
              setSelectedUser(value)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Usuário"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            noOptionsText="Nenhum resultado encontrado"
            value={selectedStudent}
            options={studentsOptions}
            getOptionLabel={(option) => option.label}
            inputValue={selectedUser?.label}
            onChange={(_, value) =>
              setSelectedStudent(value)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Estudante"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {
                getColumns().map((column) => <TableCell key={column}>
                  {column}
                </TableCell>)
              }
              <TableCell
                key={'actions'}
                sx={actionsColStyle}
              >
                {intl.get('components.table.actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              audits.map((audit, index) => <TableRow key={index}>
                <TableCell>{formatName(audit.user.name)}</TableCell>
                <TableCell>{formatName(audit.student.name)}</TableCell>
                <TableCell>{audit.team.name}</TableCell>
                <TableCell>{audit.subject?.name ?? UTILS.DASH}</TableCell>
                <TableCell>{formatName(audit.school.name)}</TableCell>
                <TableCell>{audit.action}</TableCell>
                <TableCell
                  key={`${index}action`}
                  sx={actionsColStyle}
                >
                  <Tooltip title={intl.get('components.table.view')}>
                    <IconButton
                      aria-label={intl.get('components.table.view')}
                      color="primary"
                      onClick={() => navigate(ROUTES.AUDITS_VIEW.replace(':id', audit.id.toString()))}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>)
            }
          </TableBody>

        </Table>
      </TableContainer>
      <NoResultsBox
        filtered={!!selectedStudent || !!selectedUser}
        show={!!!audits.length && !isLoading}
        type={Type.audit}
      />
      <TablePagination
        component="div"
        rowsPerPageOptions={PAGINATION.ROWS_PER_PAGE}
        count={pagination.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={(_, page) => setPage(page) }
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
}

export default Audits;
