import React, { ReactElement, useEffect } from 'react';
import { Routes as Switch, Route, useLocation, useNavigate } from 'react-router-dom';

import { CREATE_PASSWORD, PERMISSIONS, RESET_PASSWORD, ROUTES } from 'shared/constants';
import { useAppSelector } from 'store/hooks';

import NotFound from 'pages/notFound';
import Login from 'pages/login';
import Home from 'pages/home';

import Users from 'pages/users';
import FormUser from 'pages/users/form';

import Schools from 'pages/schools';
import FormSchool from 'pages/schools/form';
import ImageFormSchool from 'pages/schools/imageForm';

import Students from 'pages/students';
import FormStudent from 'pages/students/form';
import ViewStudent from 'pages/students/view';

import Subjects from 'pages/subjects';
import FormSubject from 'pages/subjects/form';

import Teams from 'pages/teams';
import FormTeam from 'pages/teams/form';
import ViewTeam from 'pages/teams/view';

import ViewReport from 'pages/reports/view';

import Highlights from 'pages/highlights';
import FormHighlight from 'pages/highlights/form';

import Segments from 'pages/segments';
import FormSegment from 'pages/segments/form';
import ViewSchool from 'pages/schools/view';
import ReportsByStudent from 'pages/reports';
import Audits from 'pages/audits';
import ViewAudit from 'pages/audits/view';

import Layout from 'components/Layout';
import LoginLayout from 'components/Layout/login';
import BlankLayout from 'components/Layout/blank';
import PrintLayout from 'components/Layout/print';

/**
 * Application routes
 * @return {JSX.Element}
 */
export default function Routes(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { logged, loading, user } = useAppSelector((state) => state.auth);
  const { viewSchoolId } = useAppSelector((state) => state.viewSchool);

  useEffect(() => {
    if (
      !loading &&
      !logged &&
      ![ROUTES.FORGOT_PASSWORD, ROUTES.RESET_PASSWORD].includes(location.pathname) &&
      location.pathname.search(RESET_PASSWORD) === -1 &&
      location.pathname.search(CREATE_PASSWORD) === -1
    ) {
      navigate(ROUTES.LOGIN);
    }
  }, [navigate, logged]);

  const checkPermission = (route: ReactElement, permissions: string[]) => {
    if (user && (permissions.includes(user.role.id.toString()) || viewSchoolId)) {
      return route;
    }
    return <></>;
  };

  return (
    <Switch>
      {
        logged ? <Route path={ROUTES.ROOT} element={<Layout />}>
          <Route path={ROUTES.ROOT} element={<Home />} />
          {
            checkPermission(
              <Route path={ROUTES.USERS}>
                {checkPermission(<Route path={ROUTES.USERS} element={<Users />} />, PERMISSIONS.USERS.LIST)}
                {checkPermission(<Route path={ROUTES.USERS_ADD} element={<FormUser />} />, PERMISSIONS.USERS.CREATE)}
                {checkPermission(<Route path={ROUTES.USERS_EDIT} element={<FormUser />} />, PERMISSIONS.USERS.EDIT)}
              </Route>,
              PERMISSIONS.USERS.LIST,
            )
          }
          {
            checkPermission(
              <Route path={ROUTES.SCHOOLS}>
                {checkPermission(<Route path={ROUTES.SCHOOLS} element={<Schools />} />, PERMISSIONS.SCHOOLS.LIST)}
                {checkPermission(<Route path={ROUTES.SCHOOLS_VIEW} element={<ViewSchool />} />, PERMISSIONS.SCHOOLS.VIEW)}
                {checkPermission(<Route path={ROUTES.SCHOOLS_ADD} element={<FormSchool />} />, PERMISSIONS.SCHOOLS.CREATE)}
                {checkPermission(<Route path={ROUTES.SCHOOLS_EDIT} element={<FormSchool />} />, PERMISSIONS.SCHOOLS.EDIT)}
              </Route>,
              PERMISSIONS.SCHOOLS.LIST,
            )
          }
          {
            checkPermission(<Route path={ROUTES.SCHOOLS_EDIT_IMAGE} element={<ImageFormSchool />} />, PERMISSIONS.SCHOOLS.EDIT_IMAGE)
          }
          {
            checkPermission(
              <Route path={ROUTES.HIGHLIGHTS}>
                {checkPermission(<Route path={ROUTES.HIGHLIGHTS} element={<Highlights />} />, PERMISSIONS.HIGHLIGHTS.LIST)}
                {checkPermission(<Route path={ROUTES.HIGHLIGHTS_ADD} element={<FormHighlight />} />, PERMISSIONS.HIGHLIGHTS.CREATE)}
                {checkPermission(<Route path={ROUTES.HIGHLIGHTS_EDIT} element={<FormHighlight />} />, PERMISSIONS.HIGHLIGHTS.EDIT)}
              </Route>,
              PERMISSIONS.HIGHLIGHTS.LIST,
            )
          }
          {
            checkPermission(
              <Route path={ROUTES.STUDENTS}>
                {checkPermission(<Route path={ROUTES.STUDENTS} element={<Students />} />, PERMISSIONS.STUDENTS.LIST)}
                {checkPermission(<Route path={ROUTES.STUDENTS_ADD} element={<FormStudent />} />, PERMISSIONS.STUDENTS.CREATE)}
                {checkPermission(<Route path={ROUTES.STUDENTS_EDIT} element={<FormStudent />} />, PERMISSIONS.STUDENTS.EDIT)}
                {checkPermission(<Route path={ROUTES.STUDENTS_VIEW_REPORTS} element={<ReportsByStudent />} />, PERMISSIONS.STUDENTS.VIEW)}
              </Route>,
              [...PERMISSIONS.STUDENTS.LIST, ...PERMISSIONS.STUDENTS.VIEW],
            )
          }
          {
            checkPermission(
              <Route path={ROUTES.SUBJECTS}>
                {checkPermission(<Route path={ROUTES.SUBJECTS} element={<Subjects />} />, PERMISSIONS.SUBJECTS.LIST)}
                {checkPermission(<Route path={ROUTES.SUBJECTS_ADD} element={<FormSubject />} />, PERMISSIONS.SUBJECTS.CREATE)}
                {checkPermission(<Route path={ROUTES.SUBJECTS_EDIT} element={<FormSubject />} />, PERMISSIONS.SUBJECTS.EDIT)}
              </Route>,
              PERMISSIONS.SUBJECTS.LIST,
            )
          }
          {
            checkPermission(
              <Route path={ROUTES.SEGMENTS}>
                {checkPermission(<Route path={ROUTES.SEGMENTS} element={<Segments />} />, PERMISSIONS.SEGMENTS.LIST)}
                {checkPermission(<Route path={ROUTES.SEGMENTS_ADD} element={<FormSegment />} />, PERMISSIONS.SEGMENTS.CREATE)}
                {checkPermission(<Route path={ROUTES.SEGMENTS_EDIT} element={<FormSegment />} />, PERMISSIONS.SEGMENTS.EDIT)}
              </Route>,
              PERMISSIONS.SEGMENTS.LIST,
            )
          }
          {checkPermission(<Route path={ROUTES.TEAMS} element={<Teams />} />, PERMISSIONS.TEAMS.LIST)}
          {checkPermission(<Route path={ROUTES.TEAMS_ADD} element={<FormTeam />} />, PERMISSIONS.TEAMS.CREATE)}
          {checkPermission(<Route path={ROUTES.TEAMS_EDIT} element={<FormTeam />} />, PERMISSIONS.TEAMS.EDIT)}
          {checkPermission(<Route path={ROUTES.TEAMS_VIEW} element={<ViewTeam />} />, PERMISSIONS.TEAMS.VIEW)}
          {checkPermission(<Route path={ROUTES.TEAMS_VIEW_STUDENTS_VIEW} element={<ViewStudent />} />, PERMISSIONS.STUDENTS.VIEW)}

          {checkPermission(<Route path={ROUTES.SPECIAL_TEAMS} element={<Teams />} />, PERMISSIONS.TEAMS.LIST)}
          {checkPermission(<Route path={ROUTES.SPECIAL_TEAMS_ADD} element={<FormTeam />} />, PERMISSIONS.TEAMS.CREATE)}
          {checkPermission(<Route path={ROUTES.SPECIAL_TEAMS_EDIT} element={<FormTeam />} />, PERMISSIONS.TEAMS.EDIT)}
          {checkPermission(<Route path={ROUTES.SPECIAL_TEAMS_VIEW} element={<ViewTeam />} />, PERMISSIONS.TEAMS.VIEW)}
          {checkPermission(<Route path={ROUTES.SPECIAL_TEAMS_STUDENTS_VIEW} element={<ViewStudent />} />, PERMISSIONS.STUDENTS.VIEW)}

          {checkPermission(<Route path={ROUTES.AUDITS} element={<Audits />} />, PERMISSIONS.AUDIT.LIST)}
          {checkPermission(<Route path={ROUTES.AUDITS_VIEW} element={<ViewAudit />} />, PERMISSIONS.AUDIT.VIEW)}
        </Route> : null
      }
      <Route path={ROUTES.ROOT} element={<LoginLayout />}>
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.CREATE_PASSWORD} element={<Login />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<Login />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<Login />} />
      </Route>
      <Route path={ROUTES.ROOT} element={<BlankLayout />}>
        <Route path='*' element={<NotFound />} />
      </Route>
      <Route path={ROUTES.ROOT} element={<PrintLayout />}>
        <Route path={ROUTES.REPORTS_PARTIAL_VIEW} element={<ViewReport />} />
        <Route path={ROUTES.REPORTS_VIEW} element={<ViewReport />} />
      </Route>
    </Switch>
  );
}
