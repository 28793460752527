import React, { Fragment } from 'react';

import {
  StyleSheet,
  Document,
  Page,
  View,
  Text,
  Image,
} from '@react-pdf/renderer';
import intl from 'react-intl-universal';

import Report, { ReportSubject } from 'models/Report';

import { UTILS } from 'shared/constants';

import { groupBy } from 'helpers/array';

import WaterMarkImage from 'assets/image/ii-opacity.png';
import ConditionalContainer from '../../ConditionalContainer';

const goalsLegend = [
  {
    range: '8, 9, 10',
    abbreviation: 'MC',
    description: 'Meta compreendida',
    color: 'blue',
  },
  {
    range: '6, 7',
    abbreviation: 'MPC +',
    description: 'Meta parcialmente compreendida com perspectiva positiva',
    color: 'green',
  },
  {
    range: '4, 5',
    abbreviation: 'MPC',
    description: 'Meta parcialmente compreendida repetida no próximo PIDE',
    color: 'yellow',
  },
  {
    range: '1, 2, 3',
    abbreviation: 'MSC',
    description: 'Meta a ser compreendida',
    color: 'red',
  },
];

const styles = StyleSheet.create({
  cover: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  page: {
    margin: 10,
    padding: 10,
    paddingBottom: 30,
  },
});

interface Props {
  partial?: boolean;
  report: Report;
  goalsCharts: Map<number, string>;
  weeksDevelopmentCharts: Map<number, string>;
  images: Map<number, string>;
  schoolImage: string;
}

/**
 * ReportDocument component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function ReportDocument(props: Props): JSX.Element {
  const showWeeksDevelopmentCharts = (reportSubject: ReportSubject) =>
    !!reportSubject.week1 ||
    !!reportSubject.week2 ||
    !!reportSubject.week3 ||
    !!reportSubject.week4 ||
    !!reportSubject.week5 ||
    !!reportSubject.week6 ||
    !!reportSubject.week7 ||
    !!reportSubject.week8 ||
    !!reportSubject.week9 ||
    !!reportSubject.week10 ||
    !!reportSubject.week11 ||
    !!reportSubject.week12;

  return (
    <Document>
      <Page size={'A4'} style={{ ...styles.page }}>
        <View fixed>
          <View
            style={{ position: 'absolute', marginTop: '137%', paddingLeft: 20 }}
          >
            <Text
              style={{ fontSize: 12, color: '#1e4673', fontWeight: 'bold' }}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
            />
          </View>
          <Image
            // debug
            src={props.schoolImage ? props.schoolImage : WaterMarkImage}
            style={{
              objectFit: 'contain',
              position: 'absolute',
              height: '50px',
              width: '50px',
              marginTop: '124%',
              marginLeft: '80%',
            }}
          />
        </View>
        <View style={{ ...styles.cover }}>
          {props.partial ? (
            <>
              <Text
                style={{
                  fontSize: '25px',
                  marginBottom: '32px',
                  color: '#5c5a5a',
                }}
              >
                {intl.get('components.reportPDF.document.title1Partial')}
              </Text>
            </>
          ) : (
            <Text
              style={{
                fontSize: '25px',
                marginBottom: '32px',
                color: '#5c5a5a',
              }}
            >
              {intl.get('components.reportPDF.document.title')}
            </Text>
          )}
          <Text style={{ fontSize: '25px', color: '#5c5a5a' }}>
            {props.report.student.name}
          </Text>
          <Text
            style={{ fontSize: '20px', marginBottom: '32px', color: '#5c5a5a' }}
          >
            {props.report.team.name} - {props.report.school.name}
          </Text>
          {props.partial ? (
            <Text
              style={{
                fontSize: '15px',
                marginBottom: '8px',
                color: '#5c5a5a',
              }}
            >
              {intl.get('components.reportPDF.document.partial')}
            </Text>
          ) : (
            <>
              <Text
                style={{
                  fontSize: '15px',
                  marginBottom: '8px',
                  color: '#5c5a5a',
                }}
              >
                {intl.get('components.reportPDF.document.description1')}
              </Text>
              <Text
                style={{
                  fontSize: '15px',
                  marginBottom: '8px',
                  color: '#5c5a5a',
                }}
              >
                {intl.get('components.reportPDF.document.description2')}
              </Text>
              <Text style={{ fontSize: '15px', color: '#5c5a5a' }}>
                {intl.get('components.reportPDF.document.description3')}
              </Text>
            </>
          )}
          {props.report.date ? (
            <Text
              style={{ fontSize: '10px', marginTop: '12px', color: '#5c5a5a' }}
            >
              {intl.get('components.reportPDF.document.issuedAt', {
                date: props.report.date,
              })}
            </Text>
          ) : null}
        </View>
        {props.report.reportSubjects.map((reportSubject, index) => (
          <View style={{ marginRight: 20 }} break={true} key={index}>
            <Text
              style={{
                fontSize: '25px',
                marginBottom: '16px',
                color: '#1e4673',
                fontWeight: 'bold',
              }}
            >
              {reportSubject.subjectName}
            </Text>
            <Text
              style={{
                fontSize: '15px',
                marginBottom: '8px',
                color: '#1e4673',
                fontWeight: 'bold',
              }}
            >
              {intl.get('components.reportPDF.document.goals')}
            </Text>
            {reportSubject.goals.length > 0 ? (
              reportSubject.goals.map((goal, index) => (
                <Fragment key={index}>
                  <Text
                    style={{
                      fontSize: '12px',
                      marginBottom: '8px',
                      color: '#5c5a5a',
                    }}
                  >
                    <Text style={{ fontSize: '15px', color: '#1e4673' }}>
                      {`${index + 1}${UTILS.DOT}`}
                    </Text>
                    {`${UTILS.SPACE}${goal.name}`}
                  </Text>
                  <Text
                    style={{
                      fontSize: '12px',
                      marginBottom: '8px',
                      color: '#5c5a5a',
                    }}
                  >
                    {`${UTILS.SPACE}${goal.strategy}`}
                  </Text>
                </Fragment>
              ))
            ) : (
              <Text
                style={{
                  fontSize: '12px',
                  marginBottom: '16px',
                  color: '#5c5a5a',
                }}
              >
                {UTILS.BLANK}
              </Text>
            )}
            {!props.partial &&
              reportSubject.goals.length > 0 &&
              props.goalsCharts.get(reportSubject.subjectId) && (
              <>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#1e4673',
                  }}
                >
                  {intl.get('components.reportPDF.document.goalsChart')}
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    {goalsLegend.map((legend, index) => (
                      <View
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          paddingLeft: '8px',
                          paddingRight: '8px',
                          marginBottom:
                              index + 1 === goalsLegend.length ? '16px' : '1px',
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: legend.color,
                            width: '10px',
                            height: '10px',
                            border: '1px solid black',
                            borderRadius: '50%',
                          }}
                        />
                        <View style={{ padding: '2px', width: '40px' }}>
                          <Text
                            style={{
                              fontSize: '9px',
                              textAlign: 'right',
                              color: '#5c5a5a',
                            }}
                          >
                            {legend.range}
                          </Text>
                        </View>
                        <View style={{ padding: '2px', width: '30px' }}>
                          <Text style={{ fontSize: '7px', color: '#5c5a5a' }}>
                            {legend.abbreviation}
                          </Text>
                        </View>
                        <View style={{ padding: '2px' }}>
                          <Text style={{ fontSize: '7px', color: '#5c5a5a' }}>
                            {legend.description}
                          </Text>
                        </View>
                      </View>
                    ))}
                  </View>
                  <Image
                    src={props.goalsCharts.get(reportSubject.subjectId)}
                    style={{ width: '200px', marginBottom: '8px' }}
                  />
                </View>
              </>
            )}
            {/* week1 */}
            <ConditionalContainer noComponent checkIf={!!reportSubject.week1}>
              <View wrap={false}>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#1e4673',
                  }}
                >
                  {intl.get('components.reportPDF.document.week', { n: 1 })}
                </Text>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#5c5a5a',
                  }}
                >
                  {reportSubject.week1 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week2 */}
            <ConditionalContainer noComponent checkIf={!!reportSubject.week2}>
              <View wrap={false}>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#1e4673',
                  }}
                >
                  {intl.get('components.reportPDF.document.week', { n: 2 })}
                </Text>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#5c5a5a',
                  }}
                >
                  {reportSubject.week2 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week3 */}
            <ConditionalContainer noComponent checkIf={!!reportSubject.week3}>
              <View wrap={false}>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#1e4673',
                  }}
                >
                  {intl.get('components.reportPDF.document.week', { n: 3 })}
                </Text>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#5c5a5a',
                  }}
                >
                  {reportSubject.week3 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week4 */}
            <ConditionalContainer noComponent checkIf={!!reportSubject.week4}>
              <View wrap={false}>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#1e4673',
                  }}
                >
                  {intl.get('components.reportPDF.document.week', { n: 4 })}
                </Text>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#5c5a5a',
                  }}
                >
                  {reportSubject.week4 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week5 */}
            <ConditionalContainer noComponent checkIf={!!reportSubject.week5}>
              <View wrap={false}>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#1e4673',
                  }}
                >
                  {intl.get('components.reportPDF.document.week', { n: 5 })}
                </Text>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#5c5a5a',
                  }}
                >
                  {reportSubject.week5 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week6 */}
            <ConditionalContainer noComponent checkIf={!!reportSubject.week6}>
              <View wrap={false}>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#1e4673',
                  }}
                >
                  {intl.get('components.reportPDF.document.week', { n: 6 })}
                </Text>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '16px',
                    color: '#5c5a5a',
                  }}
                >
                  {reportSubject.week6 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week7 */}
            <ConditionalContainer noComponent checkIf={!!reportSubject.week7}>
              <View wrap={false}>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#1e4673',
                  }}
                >
                  {intl.get('components.reportPDF.document.week', { n: 7 })}
                </Text>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#5c5a5a',
                  }}
                >
                  {reportSubject.week7 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week8 */}
            <ConditionalContainer noComponent checkIf={!!reportSubject.week8}>
              <View wrap={false}>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#1e4673',
                  }}
                >
                  {intl.get('components.reportPDF.document.week', { n: 8 })}
                </Text>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#5c5a5a',
                  }}
                >
                  {reportSubject.week8 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week9 */}
            <ConditionalContainer noComponent checkIf={!!reportSubject.week9}>
              <View wrap={false}>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#1e4673',
                  }}
                >
                  {intl.get('components.reportPDF.document.week', { n: 9 })}
                </Text>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#5c5a5a',
                  }}
                >
                  {reportSubject.week9 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week10 */}
            <ConditionalContainer noComponent checkIf={!!reportSubject.week10}>
              <View wrap={false}>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#1e4673',
                  }}
                >
                  {intl.get('components.reportPDF.document.week', { n: 10 })}
                </Text>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#5c5a5a',
                  }}
                >
                  {reportSubject.week10 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week11 */}
            <ConditionalContainer noComponent checkIf={!!reportSubject.week11}>
              <View wrap={false}>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#1e4673',
                  }}
                >
                  {intl.get('components.reportPDF.document.week', { n: 11 })}
                </Text>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#5c5a5a',
                  }}
                >
                  {reportSubject.week11 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week12 */}
            <ConditionalContainer noComponent checkIf={!!reportSubject.week12}>
              <View wrap={false}>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#1e4673',
                  }}
                >
                  {intl.get('components.reportPDF.document.week', { n: 12 })}
                </Text>
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '16px',
                    color: '#5c5a5a',
                  }}
                >
                  {reportSubject.week12 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* weeksDevelopmentChart */}
            {!props.partial &&
              props.weeksDevelopmentCharts.get(reportSubject.subjectId) &&
              showWeeksDevelopmentCharts(reportSubject) && (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  alignContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '12px',
                    marginBottom: '8px',
                    color: '#1e4673',
                  }}
                >
                  {intl.get(
                    'components.reportPDF.document.weeksDevelopmentChart',
                  )}
                </Text>
                <Image
                  src={props.weeksDevelopmentCharts.get(
                    reportSubject.subjectId,
                  )}
                  style={{ width: '200px', marginBottom: '8px' }}
                />
              </View>
            )}
            {!props.partial && (
              <>
                {!!reportSubject.evaluation && (
                  <>
                    <Text
                      // break
                      style={{
                        fontSize: '15px',
                        marginBottom: '8px',
                        color: '#1e4673',
                        fontWeight: 'bold',
                      }}
                    >
                      {intl.get('components.reportPDF.document.evaluation')}
                    </Text>
                    <Text
                      style={{
                        fontSize: '12px',
                        marginBottom: '16px',
                        color: '#5c5a5a',
                      }}
                    >
                      {reportSubject.evaluation || UTILS.BLANK}
                    </Text>
                  </>
                )}
                {!!reportSubject.considerations && (
                  <>
                    <Text
                      style={{
                        fontSize: '15px',
                        marginBottom: '8px',
                        color: '#1e4673',
                        fontWeight: 'bold',
                      }}
                    >
                      {intl.get('components.reportPDF.document.considerations')}
                    </Text>
                    <Text
                      style={{
                        fontSize: '12px',
                        marginBottom: '16px',
                        color: '#5c5a5a',
                      }}
                    >
                      {reportSubject.considerations || UTILS.BLANK}
                    </Text>
                  </>
                )}
                <View style={{ marginBottom: '16px' }}>
                  {reportSubject.behavioralAspects.length > 0 ? (
                    Object.entries(
                      groupBy(
                        reportSubject.behavioralAspects.filter(
                          (behavioralAspect) =>
                            behavioralAspect.frequency !==
                            intl.get('pages.viewStudent.map.n/a'),
                        ),
                        (i) => i.frequency,
                      ),
                    ).map((array, index) => (
                      <View key={index} wrap={false}>
                        {index === 0 ? (
                          <Text
                            style={{
                              fontSize: '15px',
                              marginBottom: '8px',
                              color: '#1e4673',
                              fontWeight: 'bold',
                            }}
                          >
                            {intl.get('components.reportPDF.document.behavior')}
                          </Text>
                        ) : null}
                        <Text
                          style={{
                            fontSize: '13px',
                            marginBottom: '8px',
                            color: '#1e4673',
                            fontWeight: 'bold',
                          }}
                        >
                          {array[0]}:
                        </Text>
                        {array[1].map(
                          (reportSubjectBehavioralAspect, index) => (
                            <Text
                              key={index}
                              style={{
                                fontSize: '12px',
                                marginBottom: '8px',
                                color: '#5c5a5a',
                              }}
                            >
                              {reportSubjectBehavioralAspect.description}
                            </Text>
                          ),
                        )}
                      </View>
                    ))
                  ) : (
                    <Text
                      style={{
                        fontSize: '12px',
                        marginBottom: '16px',
                        color: '#5c5a5a',
                      }}
                    >
                      {UTILS.BLANK}
                    </Text>
                  )}
                </View>
                {reportSubject.image &&
                  props.images.get(reportSubject.image.id) ? (
                    <View
                      style={{
                        height: '400px',
                        width: '400px',
                        marginBottom: '16px',
                        color: '#5c5a5a',
                      }}
                      break
                    >
                      <Text
                        style={{
                          fontSize: '15px',
                          marginBottom: '8px',
                          color: '#1e4673',
                          fontWeight: 'bold',
                        }}
                      >
                        {intl.get('components.reportPDF.document.visualRecord')}
                      </Text>
                      <Image
                        src={props.images.get(reportSubject.image.id)}
                        style={{
                          marginBottom: '16px',
                          borderRadius: '8px',
                        }}
                      />
                    </View>
                  ) : null}
              </>
            )}
          </View>
        ))}
      </Page>
    </Document>
  );
}
