import { Grid, Typography } from '@mui/material';

import React, { useEffect, useMemo, useState } from 'react';

import intl from 'react-intl-universal';
import { useNavigate, useParams } from 'react-router-dom';

import { ENDPOINTS, ROUTES, UTILS } from 'shared/constants';
import { initIntl } from 'shared/locales';
import api from 'shared/api';

import Audit from 'models/Audit';
import Breadcrumbs from 'components/Breadcrumbs';

initIntl();

/**
 * ViewAudit component
 * @return {JSX.Element}
 */
function ViewAudit(): JSX.Element {
  const [audit, setAudit] = useState<Audit>();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    handleGetAudit();
  }, [id]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.get('pages.audits.title'),
        onClickCallback: () => navigate(ROUTES.AUDITS),
      },
      {
        name: intl.get('common.view'),
        onClickCallback: () => { },
      },
    ];
  }, [id]);

  const handleGetAudit = async () => {
    if (id) {
      const response = await api.get(ENDPOINTS.AUDITS.GET.BY_ID.replace(':id', id));
      setAudit(response.data);
    }
  };

  return (<>
    <Breadcrumbs items={breadcrumbs} />
    <Grid container>
      <Grid item xs={12}>
        <Typography fontWeight="bold">{intl.get('pages.audits.user')}</Typography>
        <Typography>{audit?.user.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="bold">{intl.get('pages.audits.student')}</Typography>
        <Typography>{audit?.student.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="bold">{intl.get('pages.audits.team')}</Typography>
        <Typography>{audit?.team.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="bold">{intl.get('pages.audits.subject')}</Typography>
        <Typography>{audit?.subject?.name ?? UTILS.DASH}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="bold">{intl.get('pages.audits.school')}</Typography>
        <Typography>{audit?.school.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="bold">{intl.get('pages.audits.date')}</Typography>
        <Typography>{audit?.date}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="bold">{intl.get('pages.audits.ip')}</Typography>
        <Typography>{audit?.ip}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="bold">{intl.get('pages.audits.action')}</Typography>
        <Typography>{audit?.action}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="bold">{intl.get('pages.audits.oldValue')}</Typography>
        <Typography>{audit?.oldValue ? audit?.oldValue: UTILS.DASH}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="bold">{intl.get('pages.audits.newValue')}</Typography>
        <Typography>{audit?.newValue ? audit?.newValue: UTILS.DASH}</Typography>
      </Grid>
    </Grid>
  </>);
}

export default ViewAudit;
