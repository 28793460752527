import React, { useState } from 'react';

import { FormControl, FormControlLabel, FormLabel, Grid, LinearProgress, Radio, RadioGroup, SxProps, Typography } from '@mui/material';
import { red } from '@mui/material/colors';

import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import api from 'shared/api';
import { ENDPOINTS, ROLES, UTILS } from 'shared/constants';

import BehavioralAspect from 'models/BehavioralAspect';
import { ReportSubjectBehavioralAspect } from 'models/ReportSubjectBehavioralAspect';

import { displayError } from 'helpers/http';

import { useToastify } from 'hooks/toastfy';

import { useAppSelector } from 'store/hooks';

import ConditionalContainer from 'components/ConditionalContainer';
import { Theme } from '@emotion/react';

const radioStyle: SxProps<Theme> = {
  'color': red[800],
  '&.Mui-checked': {
    color: red[600],
  },
};

interface Props {
  behavioralAspects: BehavioralAspect[];
  userBelongsToSubject: boolean;
  reportSubjectId: string;
  reportSubjectBehavioralAspects: Map<number, ReportSubjectBehavioralAspect>
  setReportSubjectBehavioralAspects: (value: Map<number, ReportSubjectBehavioralAspect>) => void
  shouldRefresh: () => {}
}

/**
 * BehavioralAspects component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function BehavioralAspects(props: Props): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const toast = useToastify();
  const [isSubmiting, setIsSubmiting] = useState(false);

  const handleBehavioralAspectChange = async (frequency: string, behavioralAspectId: number) => {
    try {
      const newReportSubjectBehavioralAspects = new Map(props.reportSubjectBehavioralAspects);

      newReportSubjectBehavioralAspects.set(behavioralAspectId, {
        id: 0,
        reportSubjectId: parseInt(props.reportSubjectId),
        behavioralAspectId,
        frequency,
      });

      props.setReportSubjectBehavioralAspects(newReportSubjectBehavioralAspects);

      const data = {
        frequency,
        'report_subject_id': +props.reportSubjectId,
        'behavioral_aspect_id': +behavioralAspectId,
      };

      setIsSubmiting(true);
      const response = await api.post(ENDPOINTS.REPORT_SUBJECT_BEHAVIORAL_ASPECTS.POST.ONE, data);
      setIsSubmiting(false);
      props.shouldRefresh();

      newReportSubjectBehavioralAspects.set(behavioralAspectId, {
        id: response.data.id,
        reportSubjectId: parseInt(props.reportSubjectId),
        behavioralAspectId,
        frequency,
      });

      props.setReportSubjectBehavioralAspects(newReportSubjectBehavioralAspects);
      toast.success(intl.get('toast.saveSuccess'));
    } catch (error) {
      setIsSubmiting(false);
      displayError(error as AxiosError);
    }
  };

  const handleBehavioralAspectValue = (behavioralAspectId: number) => {
    if (props.reportSubjectBehavioralAspects.has(behavioralAspectId)) {
      return props.reportSubjectBehavioralAspects.get(behavioralAspectId)?.frequency;
    }
    return UTILS.BLANK;
  };

  return (<>
    <Grid item xs={12}>
      <Typography color="primary" fontWeight={'bold'}>{intl.get('pages.viewStudent.map.behavior')}</Typography>
    </Grid>
    {
      props.behavioralAspects.map((behavioralAspect, index) => {
        const value = handleBehavioralAspectValue(behavioralAspect.id);
        return <Grid key={index} item xs={12}>
          <FormControl>
            <FormLabel sx={{ mb: 1 }} id="considerations-radio-buttons-group-label">
              {behavioralAspect.description}
            </FormLabel>
            <RadioGroup
              aria-labelledby="considerations-radio-buttons-group-label"
              onChange={(e) => handleBehavioralAspectChange((e.target as HTMLInputElement).value, behavioralAspect.id)}
              value={value}
              row
            >
              <FormControlLabel
                sx={{ mb: 1 }}
                value={intl.get('pages.viewStudent.map.always')}
                control={<Radio sx={!value ? radioStyle : undefined} />}
                label={intl.get('pages.viewStudent.map.always')}
                disabled={(user && user.role.id.toString() === ROLES.TEACHER && !props.userBelongsToSubject) || isSubmiting}
              />
              <FormControlLabel
                sx={{ mb: 1 }}
                value={intl.get('pages.viewStudent.map.eventually')}
                control={<Radio sx={!value ? radioStyle : undefined} />}
                label={intl.get('pages.viewStudent.map.eventually')}
                disabled={(user && user.role.id.toString() === ROLES.TEACHER && !props.userBelongsToSubject) || isSubmiting}
              />
              <FormControlLabel
                sx={{ mb: 1 }}
                value={intl.get('pages.viewStudent.map.never')}
                control={<Radio sx={!value ? radioStyle : undefined} />}
                label={intl.get('pages.viewStudent.map.never')}
                disabled={(user && user.role.id.toString() === ROLES.TEACHER && !props.userBelongsToSubject) || isSubmiting}
              />
              <FormControlLabel
                sx={{ mb: 1 }}
                value={intl.get('pages.viewStudent.map.n/a')}
                control={<Radio sx={!value ? radioStyle : undefined} />}
                label={intl.get('pages.viewStudent.map.n/a')}
                disabled={(user && user.role.id.toString() === ROLES.TEACHER && !props.userBelongsToSubject) || isSubmiting}
              />
            </RadioGroup>
          </FormControl>
        </Grid>;
      })
    }
    <ConditionalContainer noComponent checkIf={isSubmiting}>
      <Grid item xs={12} mb={3} mt={1}>
        <Grid item xs={12} md={6}>
          {isSubmiting && <LinearProgress />}
        </Grid>
      </Grid>
    </ConditionalContainer>
  </>);
}
